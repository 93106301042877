







import { defineComponent, ref, getCurrentInstance } from '@vue/composition-api'
import { InstitutoApi } from '@/client/api/fetcher'
import { pipe } from 'fp-ts/lib/function'
import { either, taskEither } from 'fp-ts'

import ComposicaoTaxaTabela from './ComposicaoTaxaTabela.vue'
import ComposicaoTaxaFiltros from './ComposicaoTaxaFiltros.vue'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance() as any
    const usuario = proxy.$jwtDecode(localStorage.getItem('userData')).userData

    const items = ref([])

    const pesquisar = async (datas) => {
      const tarefa = await pipe(
        taskEither.tryCatch(
          async () =>
            await useJwt.post('indices/getHistoricoTaxas', {
              institutoId: usuario.institutoSelecionado,
              dataInicial: datas.dataInicial,
              dataFinal: datas.dataFinal,
              skip: 0,
              take: 100,
            }),
          (reason) => new Error(String(reason)),
        ),
      )()

      items.value = pipe(
        tarefa,
        either.fold(
          (error) => {
            console.error('Erro ao buscar dados:', error)
            return []
          },
          (response) => response.data,
        ),
      )
    }

    return {
      items,
      pesquisar,
    }
  },
  components: {
    ComposicaoTaxaFiltros,
    ComposicaoTaxaTabela,
  },
})
