<template>
  <div>
    <b-tabs pills>
      <b-tab>
        <template #title>
          <span>Cadastro de Patrimônio</span>
        </template>
        <!-- Form -->
        <b-row>
          <b-col cols="12">
            <b-card title="Cadastro de Patrimônio">
              <validation-observer ref="formIndRef">
                <b-form class="form-row">
                  <!-- Competência  -->
                  <b-col lg="3">
                    <b-form-group label="Competência" label-for="competencia">
                      <validation-provider #default="{ errors }" name="Competência" rules="required">
                        <b-form-select name="competencia" v-model="filtro.competencia" :options="competenciaOpcoes">
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Valor do Patrimônio Líquido RPPS -->
                  <b-col lg="3">
                    <b-form-group label="Valor do Patrimônio Líquido RPPS" label-for="valorpatrimonio">
                      <validation-provider #default="{ errors }" name="Valor do Patrimônio Líquido RPPS" rules="required">
                        <b-input-group>
                          <b-input-group-prepend>
                            <span class="input-group-text">R$</span>
                          </b-input-group-prepend>
                          <b-form-input
                            id="valorpatrimonio"
                            v-number="number"
                            type="text"
                            v-model="filtro.valorPatrimonio"
                            placeholder="Valor do Patrimônio Líquido RPPS"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Actions -->
                  <b-col lg="3">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      style="margin-top: 20px"
                      variant="primary"
                      @click.prevent="authESalve"
                      class="mr-1"
                      :disabled="!acesso.ConfiguracoesSistemaRestricaoRPPSEditar"
                    >
                      Salvar
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      style="margin-top: 20px"
                      variant="outline-secondary"
                      @click.prevent="limpar"
                    >
                      Limpar
                    </b-button>
                  </b-col>
                </b-form>
              </validation-observer>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="w-100">
            <b-card>
              <b-col class="w-100 p-0">
                <b-table
                  striped
                  responsive
                  class="position-relative"
                  :per-page="pesquisar.perPage"
                  :items="items"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :current-page="pesquisar.currentPage"
                >
                  <!-- Carregando -->
                  <template v-slot:table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle mr-1"></b-spinner>
                      <strong>Carregando Recurso(s)...</strong>
                    </div>
                  </template>

                  <template #cell(opcoes)="row">
                    <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                      <template #button-content>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="btn-icon"
                          title="Este botão contém as opções disponíveis para este registro"
                        >
                          <feather-icon icon="SettingsIcon" />
                        </b-button>
                      </template>
                      <b-dropdown-item @click="editar(row.item)" :disabled="!acesso.ConfiguracoesSistemaRestricaoRPPSEditar">
                        <feather-icon icon="CheckSquareIcon" size="16" />
                        <span class="align-middle ml-50">Editar</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="excluir(row.item.id)"
                        :disabled="!acesso.ConfiguracoesSistemaRestricaoRPPSEditar"
                      >
                        <feather-icon icon="XIcon" size="16" />
                        <span class="align-middle ml-50">Excluir</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>

                  <!-- Campo Patrimônio Total -->
                  <template #cell(valorPatrimonio)="row">
                    {{ row.item.valorPatrimonio ? formatarValor(Number(row.item.valorPatrimonio)) : formatarValor(0) }}
                  </template>

                  <!-- Campo Valor Alocado -->
                  <template #cell(valorAlocado)="row">
                    {{ row.item.valorAlocado ? formatarValor(Number(row.item.valorAlocado)) : formatarValor(0) }}
                  </template>

                  <!-- Campo Amortização -->
                  <template #cell(valorAmortizacao)="row">
                    {{ row.item.valorAmortizacao ? formatarValor(Number(row.item.valorAmortizacao)) : formatarValor(0) }}
                  </template>

                  <!-- Campo Montante Emprestado -->
                  <template #cell(montanteEmprestado)="row">
                    {{ row.item.montanteUtilizado ? formatarValor(Number(row.item.montanteUtilizado)) : formatarValor(0) }}
                  </template>
                  <template #cell(montanteUtilizadoCumulativo)="row">
                    {{
                      row.item.montanteUtilizadoCumulativo
                        ? formatarValor(Number(row.item.montanteUtilizadoCumulativo))
                        : formatarValor(0)
                    }}
                  </template>

                  <!-- Campo Valor Disponível -->
                  <template #cell(valorDisponivel)="row">
                    {{ row.item.valorDisponivel ? formatarValor(Number(row.item.valorDisponivel)) : formatarValor(0) }}
                  </template>

                  <template #cell(amortizacaoNoMes)="row">
                    {{ row.item.amortizacaoNoMes ? formatarValor(Number(row.item.amortizacaoNoMes)) : formatarValor(0) }}
                  </template>

                  <template #cell(capitalAplicado)="row">
                    {{ row.item.capitalAplicado ? formatarValor(Number(row.item.capitalAplicado)) : formatarValor(0) }}
                  </template>

                  <template #cell(valorEmprestadoAcumulado)="row">
                    {{
                      row.item.valorEmprestadoAcumulado
                        ? formatarValor(Number(row.item.valorEmprestadoAcumulado))
                        : formatarValor(0)
                    }}
                  </template>
                </b-table>

                <!-- Paginação -->
                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                  <!-- page length -->
                  <b-form-group
                    style="align-items: center"
                    label="Por Página"
                    label-cols="6"
                    label-align="left"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-1"
                  >
                    <b-form-select id="perPageSelectInd" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
                  </b-form-group>

                  <!-- pagination -->
                  <b-pagination
                    v-model="pesquisar.currentPage"
                    :total-rows="totalRows"
                    :per-page="pesquisar.perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-card-body>

                <!-- Modal de Senha -->
                <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
                  <AutenticaSenha @autenticarEvent="autenticarMethod" />
                </b-modal>
              </b-col>
            </b-card>
          </b-col>
        </b-row>

        <!-- INICIO DO VERIFICA SENHA -->
        <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
          <AutenticaSenha @autenticarEvent="autenticarMethod" />
        </b-modal>
      </b-tab>

      <b-tab>
        <template #title>
          <span>Capital Aplicado</span>
        </template>
        <b-row>
          <b-col cols="12">
            <b-card title="Capital Aplicado">
              <validation-observer ref="formIndRef">
                <b-form class="form-row">
                  <b-col cols="2">
                    <b-form-group label="Ano de Competência" label-for="anoDeCompetencia">
                      <validation-provider #default="{ errors }" name="Ano de Competência" rules="required">
                        <v-select-pt
                          id="anoDeCompetencia"
                          name="anoDeCompetencia"
                          v-model="filtroCapital.ano"
                          :options="anoOpcoes"
                          :reduce="(option) => option.value"
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="2">
                    <b-form-group label="Mês de Competência" label-for="mesDeCompetencia">
                      <validation-provider #default="{ errors }" name="Mês de Competência" rules="required">
                        <v-select-pt
                          id="mesDeCompetencia"
                          name="mesDeCompetencia"
                          v-model="filtroCapital.mes"
                          :options="mesOpcoes"
                          :reduce="(option) => option.value"
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="auto">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      style="margin-top: 21px; width: 160px"
                      variant="primary"
                      @click.prevent="pesquisarCapitalAplicado"
                    >
                      Pesquisar
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      style="margin-top: 21px; width: 200px"
                      variant="primary"
                      @click.prevent="enviarPortifolioInvestimentos"
                    >
                      Enviar Investimentos
                    </b-button>
                  </b-col>
                </b-form>
              </validation-observer>
            </b-card>
          </b-col>
        </b-row>

        <b-row v-if="itemsCapitalAplicado.length > 0">
          <b-col class="w-100">
            <b-card>
              <b-col class="w-100 p-0">
                <b-table
                  striped
                  responsive
                  class="position-relative"
                  :per-page="pesquisar.perPage"
                  :items="itemsCapitalAplicado"
                  :fields="fieldsCapitalAplicado"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :current-page="pesquisar.currentPage"
                >
                  <!-- Carregando -->
                  <template v-slot:table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle mr-1"></b-spinner>
                      <strong>Carregando Recurso(s)...</strong>
                    </div>
                  </template>

                  <!-- Campo Patrimônio Total -->
                  <template #cell(valorPatrimonio)="row">
                    {{ row.item.valorPatrimonio ? formatarValor(Number(row.item.valorPatrimonio)) : formatarValor(0) }}
                  </template>

                  <!-- Rentabilidade -->
                  <template #cell(rentabilidade)="row">
                    {{ row.item.rentabilidade ? formatarValor(Number(row.item.rentabilidade)) : formatarValor(0) }}
                  </template>

                  <!-- Percentual de Rendimento -->
                  <template #cell(percentualRentabilidadeAoCapital)="row">
                    {{
                      row.item.percentualRentabilidadeAoCapital
                        ? formatarValorPorcentagem(Number(row.item.percentualRentabilidadeAoCapital))
                        : formatarValor(0)
                    }}
                  </template>

                  <!-- Campo Valor Alocado -->
                  <template #cell(valorAlocado)="row">
                    {{ row.item.valorAlocado ? formatarValor(Number(row.item.valorAlocado)) : formatarValor(0) }}
                  </template>

                  <!-- Campo Amortização -->
                  <template #cell(valorAmortizacao)="row">
                    {{ row.item.valorAmortizacao ? formatarValor(Number(row.item.valorAmortizacao)) : formatarValor(0) }}
                  </template>

                  <!-- Campo Montante Emprestado -->
                  <template #cell(montanteEmprestado)="row">
                    {{ row.item.montanteUtilizado ? formatarValor(Number(row.item.montanteUtilizado)) : formatarValor(0) }}
                  </template>

                  <template #cell(montanteUtilizadoCumulativo)="row">
                    {{
                      row.item.montanteUtilizadoCumulativo
                        ? formatarValor(Number(row.item.montanteUtilizadoCumulativo))
                        : formatarValor(0)
                    }}
                  </template>

                  <!-- Campo Valor Disponível -->
                  <template #cell(valorDisponivel)="row">
                    {{ row.item.valorDisponivel ? formatarValor(Number(row.item.valorDisponivel)) : formatarValor(0) }}
                  </template>

                  <template #cell(amortizacaoNoMes)="row">
                    {{ row.item.amortizacaoNoMes ? formatarValor(Number(row.item.amortizacaoNoMes)) : formatarValor(0) }}
                  </template>

                  <template #cell(capitalAplicado)="row">
                    {{ row.item.capitalAplicado ? formatarValor(Number(row.item.capitalAplicado)) : formatarValor(0) }}
                  </template>

                  <template #cell(valorEmprestadoAcumulado)="row">
                    {{
                      row.item.valorEmprestadoAcumulado
                        ? formatarValor(Number(row.item.valorEmprestadoAcumulado))
                        : formatarValor(0)
                    }}
                  </template>
                </b-table>

                <!-- Paginação -->
                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                  <!-- page length -->
                  <b-form-group
                    style="align-items: center"
                    label="Por Página"
                    label-cols="6"
                    label-align="left"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-1"
                  >
                    <b-form-select id="perPageSelectInd" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
                  </b-form-group>

                  <!-- pagination -->
                  <b-pagination
                    v-model="pesquisar.currentPage"
                    :total-rows="totalRows"
                    :per-page="pesquisar.perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-card-body>

                <!-- Modal de Senha -->
                <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
                  <AutenticaSenha @autenticarEvent="autenticarMethod" />
                </b-modal>
              </b-col>
            </b-card>
          </b-col>
        </b-row>

        <!-- INICIO DO VERIFICA SENHA -->
        <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
          <AutenticaSenha @autenticarEvent="autenticarMethod" />
        </b-modal>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  //validacao
  import VSelectPT from '@/@core/components/v-select-pt/VSelectPT.vue'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarParaNumber, formatarValor, formatarValorPorcentagem, isLetter } from '@/libs/utils'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import { required } from '@validations'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Cleave from 'vue-cleave-component'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      Cleave,
      AutenticaSenha,
      NaoAutorizadoModal,
      formatarValor,
      formatarValorPorcentagem,
      VSelectPT,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        filtro: { exercicio: '', competencia: '', valorPatrimonio: 0.0, rentabilidadeConsignacao: 0.0 },
        competenciaOpcoes: [],
        number: 0.0,
        formatarValor,
        formatarValorPorcentagem,
        isLetter,
        itemsCapitalAplicado: [],

        mode: 'insert',

        filtroCapital: {
          ano: '',
          mes: '',
        },
        anoOpcoes: [
          { value: 2021, text: '2021' },
          { value: 2022, text: '2022' },
          { value: 2023, text: '2023' },
          { value: 2024, text: '2024' },
          { value: 2025, text: '2025' },
          { value: 2026, text: '2026' },
          { value: 2027, text: '2027' },
          { value: 2028, text: '2028' },
          { value: 2029, text: '2029' },
          { value: 2030, text: '2030' },
        ],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],

        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        pesquisar: { perPage: 25, currentPage: 1 },
        acesso: {
          ConfiguracoesSistemaRestricaoRPPSEditar: false,
        },

        //Campos tabela
        fields: [
          { key: 'opcoes', label: 'Opções' },
          {
            key: 'competencia',
            label: 'Competência',
            sortable: true,
            formatter: (value) => {
              let mes = String(value).substring(4, 6)
              let ano = String(value).substring(0, 4)

              if (mes == 1) return 'Janeiro/' + ano
              if (mes == 2) return 'Fevereiro/' + ano
              if (mes == 3) return 'Março/' + ano
              if (mes == 4) return 'Abril/' + ano
              if (mes == 5) return 'Maio/' + ano
              if (mes == 6) return 'Junho/' + ano
              if (mes == 7) return 'Julho/' + ano
              if (mes == 8) return 'Agosto/' + ano
              if (mes == 9) return 'Setembro/' + ano
              if (mes == 10) return 'Outubro/' + ano
              if (mes == 11) return 'Novembro/' + ano
              if (mes == 12) return 'Dezembro/' + ano
            },
          },
          { key: 'valorPatrimonio', label: 'Patrimônio Total', sortable: true },
          { key: 'valorAlocado', label: 'Valor Alocado', sortable: true },
          { key: 'montanteEmprestado', label: 'Montante Emprestado', sortable: true },
          { key: 'valorEmprestadoAcumulado', label: 'Montante Acumulado', sortable: true },
          { key: 'amortizacaoNoMes', label: 'Amortização', sortable: true },
          { key: 'valorDisponivel', label: 'Valor Disponível', sortable: true },
        ],
        fieldsCapitalAplicado: [
          {
            key: 'competencia',
            label: 'Competência',
            sortable: true,
            formatter: (value) => {
              let mes = String(value).substring(4, 6)
              let ano = String(value).substring(0, 4)

              if (mes == 1) return 'Janeiro/' + ano
              if (mes == 2) return 'Fevereiro/' + ano
              if (mes == 3) return 'Março/' + ano
              if (mes == 4) return 'Abril/' + ano
              if (mes == 5) return 'Maio/' + ano
              if (mes == 6) return 'Junho/' + ano
              if (mes == 7) return 'Julho/' + ano
              if (mes == 8) return 'Agosto/' + ano
              if (mes == 9) return 'Setembro/' + ano
              if (mes == 10) return 'Outubro/' + ano
              if (mes == 11) return 'Novembro/' + ano
              if (mes == 12) return 'Dezembro/' + ano
            },
          },
          { key: 'capitalAplicado', label: 'Capital Aplicado', sortable: true },
          { key: 'montanteEmprestado', label: 'Montante Emprestado', sortable: true },
          { key: 'amortizacaoNoMes', label: 'Amortização', sortable: true },
          { key: 'rentabilidade', label: 'Rentabilidade', sortable: true },
          { key: 'percentualRentabilidadeAoCapital', label: 'Percentual', sortable: true },
        ],
        items: [],

        //Padrão Tabela
        sortBy: 'competencia',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
        },
        striped: true,
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaRestricaoRPPSEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaRestricaoRPPSEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaRestricaoRPPSEditar
      }

      this.getCompetencias()
      this.carregarGrid()
    },
    methods: {
      limpar() {
        this.filtro = {
          exercicio: '',
          competencia: '',
        }
        this.mode = 'insert'
        delete this.filtro.id
        this.filtro.competencia = this.competenciaOpcoes[0]
        this.$refs.formIndRef.reset()
      },
      authESalve() {
        this.$refs.formIndRef.validate().then((success) => {
          if (success) {
            this.filtro.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.filtro.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        this.$swal({
          title: 'Atenção!',
          text: `Deseja realmente cadastrar o patrimônio ${formatarValor(
            formatarParaNumber(this.filtro.valorPatrimonio),
          )} para a competência ${this.filtro.competencia}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            let competenciaNumerica = this.verificaCompetencia(this.filtro.competencia)
            let cadastroMes = Number(String(competenciaNumerica).substring(4, 6))
            let cadastroAno = Number(String(competenciaNumerica).substring(0, 4))
            let porcentagemInstituto = formatarParaNumber(this.userData.Instituto.limiteAlocacao) / 100

            let items = {
              competencia: Number(competenciaNumerica),
              ano: cadastroAno,
              mes: cadastroMes,
              valorPatrimonio: formatarParaNumber(this.filtro.valorPatrimonio),
              valorAlocado: formatarParaNumber(this.filtro.valorPatrimonio) * porcentagemInstituto,
              institutoId: this.userData.institutoSelecionado,
            }

            if (this.mode == 'insert') {
              useJwt
                .post(`recursos/registrarRecursos`, items)
                .then(() => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Registro alterado com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((response) => {
                    console.debug(response)
                    this.limpar()
                    this.carregarGrid()
                  })
                })
                .catch((error) => {
                  this.limpar()
                  console.error(error)
                })
            }

            if (this.mode == 'update') {
              let id = formatarParaNumber(this.filtro.id)

              useJwt
                .put(`recursos/putRecursos/${id}`, items)
                .then(() => {
                  this.$swal({
                    title: 'Atenção!',
                    text: 'Registro alterado com sucesso',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then((response) => {
                    console.debug(response)
                    this.limpar()
                    this.carregarGrid()
                  })
                })
                .catch((error) => {
                  this.limpar()
                  console.error(error)
                })
            }
          }
        })
      },
      calculoValorAlocado(item) {
        return Number(item.valorAlocado)
      },
      calculoValorDisponivel(item) {
        return (
          item.valorAlocado +
          (item.valorAmortizacao ? Number(item.valorAmortizacao) : 0) -
          Number(item.montanteUtilizadoCumulativo)
        )
      },
      carregarGrid() {
        let institutoId = formatarParaNumber(this.userData.Instituto.id)

        useJwt
          .post('recursos/getPesquisarRecursos', { institutoId })
          .then((response) => {
            this.items = response.data
            this.totalRows = response.data.length
            this.itemsCapitalAplicado = this.items
            this.filtroCapital.ano = this.anoOpcoes[0]
            this.filtroCapital.mes = this.mesOpcoes[0].value
          })
          .catch((error) => {
            console.error(error)
          })
      },
      getCompetencias() {
        const meses = [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ]

        const dataAtual = new Date()
        const listaCompetencias = []

        for (let i = 0; i < 12; i++) {
          const mesAtual = dataAtual.getMonth()
          const anoAtual = dataAtual.getFullYear()

          const mesCompetencia = meses[mesAtual]
          const competencia = `${mesCompetencia}/${anoAtual}`
          listaCompetencias.push(competencia)

          // Avança para o mês anterior
          dataAtual.setMonth(mesAtual - 1)

          // Caso o mês atual seja janeiro, ajusta o ano
          if (mesAtual === 0) {
            dataAtual.setFullYear(anoAtual - 1)
          }
        }

        this.competenciaOpcoes = listaCompetencias
        this.filtro.competencia = listaCompetencias[0]
      },
      verificaCompetencia(filtroCompetencia) {
        if (filtroCompetencia.includes('Janeiro')) return filtroCompetencia.replace('Janeiro/', '') + '01'
        if (filtroCompetencia.includes('Fevereiro')) return filtroCompetencia.replace('Fevereiro/', '') + '02'
        if (filtroCompetencia.includes('Março')) return filtroCompetencia.replace('Março/', '') + '03'
        if (filtroCompetencia.includes('Abril')) return filtroCompetencia.replace('Abril/', '') + '04'
        if (filtroCompetencia.includes('Maio')) return filtroCompetencia.replace('Maio/', '') + '05'
        if (filtroCompetencia.includes('Junho')) return filtroCompetencia.replace('Junho/', '') + '06'
        if (filtroCompetencia.includes('Julho')) return filtroCompetencia.replace('Julho/', '') + '07'
        if (filtroCompetencia.includes('Agosto')) return filtroCompetencia.replace('Agosto/', '') + '08'
        if (filtroCompetencia.includes('Setembro')) return filtroCompetencia.replace('Setembro/', '') + '09'
        if (filtroCompetencia.includes('Outubro')) return filtroCompetencia.replace('Outubro/', '') + '10'
        if (filtroCompetencia.includes('Novembro')) return filtroCompetencia.replace('Novembro/', '') + '11'
        if (filtroCompetencia.includes('Dezembro')) return filtroCompetencia.replace('Dezembro/', '') + '12'
      },
      editar(item) {
        let competencia = this.montaTextoCompetencia(item.competencia)
        this.mode = 'update'
        this.filtro.id = item.id
        this.filtro.competencia = competencia
        this.filtro.valorPatrimonio = formatarParaNumber(item.valorPatrimonio) * 100
      },
      async excluir(item) {
        this.$swal({
          title: 'Atenção!',
          text: `Deseja realmente excluir o recurso?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            useJwt
              .delete('recursos/excluirRecursos', item)
              .then((success) => {
                if (success) {
                  this.carregarGrid()
                  this.$message.success('Recurso excluído com sucesso!')
                }
              })
              .catch((error) => {
                console.error('Erro: ', error)
                this.$message.error('Erro ao excluir o recurso')
              })
          }
        })
      },
      montaTextoCompetencia(competencia) {
        let mes = String(competencia).substring(4, 6)
        let ano = String(competencia).substring(0, 4)

        if (mes == 1) return 'Janeiro/' + ano
        if (mes == 2) return 'Fevereiro/' + ano
        if (mes == 3) return 'Março/' + ano
        if (mes == 4) return 'Abril/' + ano
        if (mes == 5) return 'Maio/' + ano
        if (mes == 6) return 'Junho/' + ano
        if (mes == 7) return 'Julho/' + ano
        if (mes == 8) return 'Agosto/' + ano
        if (mes == 9) return 'Setembro/' + ano
        if (mes == 10) return 'Outubro/' + ano
        if (mes == 11) return 'Novembro/' + ano
        if (mes == 12) return 'Dezembro/' + ano
      },
      pesquisarCapitalAplicado() {
        if (this.filtroCapital.ano && this.filtroCapital.mes) {
          const resultadoFiltrado = this.items.filter(
            (item) => item.ano === this.filtroCapital.ano && item.mes === this.filtroCapital.mes,
          )
          this.itemsCapitalAplicado = resultadoFiltrado
        }

        if (this.itemsCapitalAplicado.length === 0) {
          this.$message.error('Nenhum registro encontrado')
        }
      },
      async enviarPortifolioInvestimentos() {
        let competenciaItems
        if (this.filtroCapital.ano && this.filtroCapital.mes) {
          const resultadoFiltrado = this.items.filter(
            (item) => item.ano === this.filtroCapital.ano && item.mes === this.filtroCapital.mes,
          )
          competenciaItems = resultadoFiltrado[0]

          if (!competenciaItems || competenciaItems.length === 0) {
            this.$message.error('Nenhum item para competência selecionada')
            return
          }
        }
        const data = {
          institutoId: this.userData.institutoSelecionado,
          competencia: competenciaItems.competencia,
          valorConcedido: competenciaItems.montanteUtilizado,
          amortizacao: competenciaItems.amortizacaoNoMes,
          rentabilidade: competenciaItems.rentabilidade,
          valorTotalConcedido: competenciaItems.valorEmprestadoAcumulado,
        }
        await useJwt
          .post('integracoes/portifolioInvestimentos', data)
          .then((res) => this.$message.success('Portifólio de investimentos enviado com sucesso ao sisprev!'))
          .catch((err) => this.$message.error('Algo deu errado!'))
      },
    },
  }
</script>
