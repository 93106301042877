<template>
  <b-card>
    <template #header>
      <div>
        <h2 class="mb-0">Lista de Pendências</h2>
      </div>
    </template>

    <b-row>
      <b-col>
        <b-button
          :disabled="!acesso.ConfiguracoesSistemaListaPendenciasCadastrar"
          variant="primary"
          @click="abrirFormularioCadastro()"
          class="botao-padrao mb-1"
        >
          Cadastrar
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          id="tabela-pendencias"
          striped
          responsive
          class="mt-1 position-relative"
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :current-page="currentPage"
          :busy="isBusy"
          show-empty
        >
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong> Carregando...</strong>
            </div>
          </template>

          <template #empty>
            <div class="text-center">Nenhum registro encontrado</div>
          </template>

          <template #cell(opcoes)="row">
            <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
              <template #button-content>
                <b-button variant="primary" class="btn-icon" title="Este botão contém as opções disponíveis para este registro">
                  <feather-icon icon="SettingsIcon" />
                </b-button>
              </template>

              <b-dropdown-item
                :disabled="!acesso.ConfiguracoesSistemaListaPendenciasEditar"
                @click="editarPendencia(row.item)"
                v-b-tooltip.hover.bottom="
                  !acesso.ConfiguracoesSistemaListaPendenciasEditar ? 'Sem permissão para editar pendências' : ''
                "
              >
                <feather-icon icon="EditIcon" size="16" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                :disabled="!acesso.ConfiguracoesSistemaListaPendenciasExcluir"
                @click="excluirPendencia(row.item.id)"
                v-b-tooltip.hover.bottom="
                  !acesso.ConfiguracoesSistemaListaPendenciasEditar ? 'Sem permissão para excluir pendências' : ''
                "
              >
                <feather-icon icon="XIcon" size="16" />
                <span class="align-middle ml-50">Excluir</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #cell(obrigatorio)="row">
            <b-badge :variant="row.item.obrigatorio ? 'danger' : 'primary'">
              {{ row.item.obrigatorio ? 'Sim' : 'Não' }}
            </b-badge>
          </template>
        </b-table>

        <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            style="align-items: center"
            label="Por Página"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
          </b-form-group>

          <!-- pagination -->
          <b-pagination
            first-number
            last-number
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-card-body>
      </b-col>
    </b-row>

    <b-modal id="modal-cadastro-status" ref="modalCadastroStatus" centered no-close-on-backdrop hide-footer no-close-on-esc>
      <validation-observer ref="formularioListaPendencias">
        <b-card class="modalCadastroPendencias">
          <template #header>
            <div class="d-flex justify-content-center w-100">
              <h2 class="mb-0">{{ tituloModalCadastro }}</h2>
            </div>
          </template>

          <b-row>
            <b-col md="12">
              <b-form-group label="Nome" label-for="nome">
                <validation-provider #default="{ errors }" name="Nome" rules="required">
                  <b-form-input id="nome" name="nome" type="text" v-model="formulario.nome" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Obrigatório para Consolidar o Contrato?" label-for="obrigatorio">
                <b-form-checkbox id="obrigatorio" v-model="formulario.obrigatorio" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-button block variant="primary" @click="salvarPendencia"> Salvar </b-button>
            </b-col>
          </b-row>
        </b-card>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { message, sweetAlert } from '@/main'
  import { reactive, ref } from '@vue/composition-api'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import { jwtDecode } from 'jwt-decode'

  export default {
    name: 'ListaPendencias',
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    props: {
      generalData: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      // Configuração da tabela
      const fields = [
        { key: 'opcoes', label: 'Opções' },
        { key: 'nome', label: 'Nome' },
        { key: 'obrigatorio', label: 'Obrigatório para Consolidar o Contrato?' },
      ]
      const items = ref([])
      const sortBy = ref('nome')
      const sortDesc = ref(false)
      const sortDirection = ref('asc')
      const currentPage = ref(1)
      const isBusy = ref(false)
      const perPage = 10
      const totalRows = ref(0)
      const pageOptions = [10, 20, 30]

      // Permissions
      const userData = jwtDecode(localStorage.getItem('userData')).userData
      let acesso = {
        ConfiguracoesSistemaListaPendenciasCadastrar: true,
        ConfiguracoesSistemaListaPendenciasEditar: true,
        ConfiguracoesSistemaListaPendenciasExcluir: true,
      }
      if (userData.role != 'master') {
        acesso.ConfiguracoesSistemaListaPendenciasCadastrar = userData.GrupoAcesso.ConfiguracoesSistemaListaPendenciasCadastrar
        acesso.ConfiguracoesSistemaListaPendenciasEditar = userData.GrupoAcesso.ConfiguracoesSistemaListaPendenciasEditar
        acesso.ConfiguracoesSistemaListaPendenciasExcluir = userData.GrupoAcesso.ConfiguracoesSistemaListaPendenciasExcluir
      }

      // Configuração de Formulário
      const tituloModalCadastro = ref('Cadastrar Pendência')
      const isCreating = ref(true)
      const modalCadastroStatus = ref(null)
      const formulario = reactive({
        id: '',
        nome: '',
        obrigatorio: false,
      })

      // Methods
      const carregarGrid = async () => {
        isBusy.value = true
        try {
          const { data } = await useJwt.post('lista-pendencias/getPesquisar', {
            institutoId: props.generalData.id,
          })

          // Mapeia os dados para o formato da tabela
          items.value = data.map((item) => ({
            id: item.id,
            nome: item.nome,
            obrigatorio: item.obrigatorio,
            opcoes: null,
          }))

          // Ordena a lista de pendências por nome
          items.value.sort((a, b) => a.nome.localeCompare(b.nome))

          // Atualiza o total de registros
          totalRows.value = data.length
        } catch (error) {
          console.error(error)
          message.error('Erro ao carregar a lista de pendências')
        } finally {
          isBusy.value = false
        }
      }

      const abrirFormularioCadastro = async () => {
        formulario.id = null
        formulario.nome = ''
        formulario.obrigatorio = false
        isCreating.value = true
        tituloModalCadastro.value = 'Cadastrar Pendência'
        modalCadastroStatus.value.show()
      }

      const salvarPendencia = async () => {
        isBusy.value = true
        try {
          if (isCreating.value) {
            await useJwt.post('lista-pendencias', {
              institutoId: props.generalData.id,
              nome: formulario.nome,
              obrigatorio: formulario.obrigatorio,
            })
          } else {
            await useJwt.patch(`lista-pendencias/${formulario.id}`, {
              nome: formulario.nome,
              obrigatorio: formulario.obrigatorio,
              institutoId: props.generalData.id,
            })
          }

          carregarGrid()
          modalCadastroStatus.value.hide()

          sweetAlert({
            title: 'Pendência salva com sucesso!',
            icon: 'success',
          })
        } catch (error) {
          console.error(error)

          if (error.response.data.statusCode === 409) {
            return sweetAlert({
              title: 'Atenção!',
              text: 'Já existe uma pendência com esse nome cadastrado.',
              icon: 'warning',
            })
          }

          message.error('Erro ao carregar a lista de pendências')
        } finally {
          isBusy.value = false
          isCreating.value = true
        }
      }

      const editarPendencia = async (item) => {
        isCreating.value = false
        tituloModalCadastro.value = 'Editar Pendência'
        formulario.id = item.id
        formulario.nome = item.nome
        formulario.obrigatorio = item.obrigatorio
        modalCadastroStatus.value.show()
      }

      const excluirPendencia = (item) => {
        sweetAlert({
          title: 'Atenção!',
          text: 'Deseja realmente excluir a pendência?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async (willDelete) => {
          if (willDelete.isConfirmed) {
            try {
              await useJwt.delete('lista-pendencias', item)
              carregarGrid()

              sweetAlert({
                title: 'Pendência excluída com sucesso!',
                icon: 'success',
              })
            } catch (error) {
              console.error(error)
              if (error.response.data.statusCode === 409) {
                return sweetAlert({
                  title: 'Atenção!',
                  text: 'Não é possível excluir a pendência, pois ela está vinculada a um contrato.',
                  icon: 'warning',
                })
              }
              return message.error('Erro ao excluir a pendência')
            }
          }
        })
      }

      carregarGrid()

      return {
        acesso,
        fields,
        items,
        sortBy,
        sortDesc,
        sortDirection,
        currentPage,
        isBusy,
        perPage,
        totalRows,
        pageOptions,
        formulario,
        tituloModalCadastro,
        isCreating,
        modalCadastroStatus,

        carregarGrid,
        abrirFormularioCadastro,
        salvarPendencia,
        editarPendencia,
        excluirPendencia,
      }
    },
  }
</script>

<style scoped>
  .modalCadastroPendencias {
    margin: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
</style>
