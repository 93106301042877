<template>
  <validation-observer ref="formIndiceIGPM">
    <b-form>
      <b-row class="p-1">
        <b-col md="6">
          <b-form-group label="Ano" label-for="ano">
            <validation-provider #default="{ errors }" name="Ano" rules="required">
              <v-select-pt
                name="ano"
                v-model="dados.ano"
                :options="anoOpcoes"
                :reduce="(option) => option.value"
                label="text"
              ></v-select-pt>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Mês" label-for="mes">
            <validation-provider #default="{ errors }" name="Mês" rules="required">
              <v-select-pt
                name="mes"
                v-model="dados.mes"
                :options="mesOpcoes"
                :reduce="(option) => option.value"
                label="text"
              ></v-select-pt>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Taxa Mensal" label-for="taxaMensal">
            <validation-provider #default="{ errors }" name="Taxa Mensal" rules="required">
              <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                <b-form-input
                  type="text"
                  v-number="number"
                  id="taxaMensal"
                  v-model="dados.taxaMensal"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  name="Taxa Mensal"
                  placeholder="Taxa Mensal"
                />
                <b-input-group-append>
                  <span class="input-group-text">%</span>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Taxa Acumulada" label-for="taxaAcumulada">
            <validation-provider #default="{ errors }" name="Taxa Acumulada" rules="required">
              <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                <b-form-input
                  type="text"
                  v-number="number"
                  id="taxaAcumulada"
                  v-model="dados.taxaAcumulada"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  name="Taxa Acumulada"
                  placeholder="Taxa Acumulada"
                />
                <b-input-group-append>
                  <span class="input-group-text">%</span>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import Cleave from 'vue-cleave-component'
  import useJwt from '@/auth/jwt/useJwt'
  import { isLetter, formatarData } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal.vue'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      AutenticaSenha,
      NaoAutorizadoModal,
      Cleave,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
      dataUpdate: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        dadosInstitutoSelecionado: {},
        isBusy: false,
        isBusyImportacao: false,
        mode: '',

        //Tabela e Paginação
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        perPage: 10,
        currentPage: 1,
        fields: [
          {
            key: 'ano',
            sortable: true,
          },
          {
            key: 'mes',
            label: 'Mês',
            sortable: true,
          },
          {
            key: 'tipoIndice',
            label: 'Tipo Índice',
            sortable: true,
          },
          {
            key: 'taxa',
            sortable: true,
          },
          {
            key: 'taxaAcumulado',
            sortable: true,
          },
          {
            key: 'updatedAt',
            label: 'Data de Atualização do Índice',
            formatter: (value) => {
              return value ? formatarData(value) : ''
            },
            sortable: true,
          },
        ],
        totalRows: 0,
        items: [],
        pesquisar: {
          tipoIndice: '',
        },
        pageOptions: [10, 25, 50, 100],

        indiceSelecionadoImportacao: '',
        anoOpcoes: [],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        indicesOpcoesImportacao: [
          { value: 'IPCA', text: 'IPCA' },
          { value: 'INPC', text: 'INPC' },
        ],
        number: {},
        dados: {
          ano: null,
          mes: null,
          taxaMensal: null,
          taxaAcumulada: null,
        },
        modoAutenticacao: '',
        isLetter,
        cleaveCompetencia: {
          blocks: [2, 4],
          delimiter: '/',
          numericOnly: true,
        },
        indiceOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'ipca', text: 'IPCA' },
          { value: 'inpc', text: 'INPC' },
          { value: 'igpm', text: 'IGPM' },
        ],
        acesso: {
          ConfiguracoesSistemaIndiceRefConsultar: null,
          ConfiguracoesSistemaIndiceRefCriarNovo: null,
          ConfiguracoesSistemaIndiceRefEditar: null,
          ConfiguracoesSistemaIndiceRefExcluir: null,
        },
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaIndiceRefConsultar = true
        this.acesso.ConfiguracoesSistemaIndiceRefCriarNovo = true
        this.acesso.ConfiguracoesSistemaIndiceRefEditar = true
        this.acesso.ConfiguracoesSistemaIndiceRefExcluir = true
      } else {
        this.acesso.ConfiguracoesSistemaIndiceRefConsultar = this.userData.GrupoAcesso.ConfiguracoesSistemaIndiceRefConsultar
        this.acesso.ConfiguracoesSistemaIndiceRefCriarNovo = this.userData.GrupoAcesso.ConfiguracoesSistemaIndiceRefCriarNovo
        this.acesso.ConfiguracoesSistemaIndiceRefEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaIndiceRefEditar
        this.acesso.ConfiguracoesSistemaIndiceRefExcluir = this.userData.GrupoAcesso.ConfiguracoesSistemaIndiceRefExcluir
      }

      this.dadosInstitutoSelecionado = this.userData.Instituto

      if (this.dataUpdate.id) {
        this.dados = {
          ...this.dataUpdate,
          taxaMensal: this.dataUpdate.taxa,
          taxaAcumulada: this.dataUpdate.taxaAcumulado,
        }
      }

      // Seta o tipo do índice para "Selecionar" e limpa a validação de vazio
      this.dados.tipoIndice = ''
      this.montaAnoOpcoes()

      // Seta o tipo do índice de acordo com o instituto selecionado
      this.dados.tipoIndice = this.dadosInstitutoSelecionado.tipoIndiceSelecionado?.toLowerCase()
      this.pesquisar.tipoIndice = this.dados.tipoIndice
    },
    methods: {
      goPesquisar() {
        this.isBusy = true
        let mesCompetenciaInicial = Number(this.pesquisar.dataInicial?.substring(0, 2))
        let anoCompetenciaInicial = Number(this.pesquisar.dataInicial?.substring(3, 7))
        let mesCompetenciaFinal = Number(this.pesquisar.dataFinal?.substring(0, 2))
        let anoCompetenciaFinal = Number(this.pesquisar.dataFinal?.substring(3, 7))

        let filtroValidado = this.validaFiltros(
          mesCompetenciaInicial,
          anoCompetenciaInicial,
          mesCompetenciaFinal,
          anoCompetenciaFinal,
        )

        let param = {
          mesCompetenciaInicial: mesCompetenciaInicial,
          anoCompetenciaInicial: anoCompetenciaInicial,
          mesCompetenciaFinal: mesCompetenciaFinal,
          anoCompetenciaFinal: anoCompetenciaFinal,
          tipoIndice: this.pesquisar.tipoIndice,
        }
        if (!this.pesquisar.tipoIndice) delete param.tipoIndice

        if (filtroValidado) {
          useJwt
            .post('indices/GetTaxas', param)
            .then((response) => {
              if (response.data.length > 0) {
                this.items = response.data
                this.totalRows = response.data.length
              } else this.$message.error('Não foram encontrados nenhum índice!')
              this.isBusy = false
            })
            .catch((error) => {
              console.error(error)
              this.isBusy = false
            })
        } else {
          this.$message.error('O filtro para pesquisa está incorreto!')
          this.isBusy = false
        }
      },
      salvarIndice() {
        let param = {
          institutoId: this.userData.institutoSelecionado,
          tipoIndice: this.dados.tipoIndice,
        }

        useJwt.post('cadastro/instituto/SetInstitutoIndice', param).then((res) => {
          this.$message.success('Índice alterado com sucesso!')

          const userData = {
            ...this.userData,
          }

          this.$emit('atualizarIndice', String(this.dados.tipoIndice).toUpperCase())
          userData.Instituto.tipoIndiceSelecionado = String(this.dados.tipoIndice).toUpperCase()
          // userData.Instituto.taxaIndiceInflacionarioMensal = String(this.dados.tipoIndice).toUpperCase()
          localStorage.setItem('userData', JSON.stringify(userData))
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.modoAutenticacao == 'salvarIndice') this.salvarIndice()
          if (this.modoAutenticacao == 'importacao') this.apresentaOpcoesIndice()
        }
      },
      validaFiltros(mesIni, anoIni, mesFim, anoFim) {
        let validado = true

        if (mesIni) if (mesIni > 12 || mesIni < 0) validado = false

        if (mesFim) if (mesFim > 12 || mesFim < 0) validado = false

        if (anoIni) if (anoIni > 2100 || anoIni < 1900) validado = false

        if (anoFim) if (anoFim > 2100 || anoFim < 1900) validado = false

        if (validado) {
          if (mesIni && anoIni && mesFim && anoFim) {
            let dataInicialFiltro = new Date(String(anoIni) + '/' + String(mesIni) + '/' + '01')
            let dataFinalFiltro = new Date(String(anoFim) + '/' + String(mesFim) + '/' + '01')
            if (dataInicialFiltro > dataFinalFiltro) validado = false
          }
        }

        if (!mesIni && anoIni) validado = false
        if (mesIni && !anoIni) validado = false
        if (!mesFim && anoFim) validado = false
        if (mesFim && !anoFim) validado = false

        if (!mesIni && !anoIni && !mesFim && !anoFim) validado = true

        return validado
      },
      apresentaModalCadastro() {
        this.$refs['modal-cadastro-igpm'].show()
      },
      salvar() {
        this.$refs.formIndiceIGPM.validate().then((success) => {
          if (success) {
            let mes = this.dados.mes
            let ano = this.dados.ano
            let competencia = ano * 100 + mes
            let taxas = {}
            let acumulados = {}

            taxas[competencia] = this.dados.taxaMensal
            acumulados[competencia] = this.dados.taxaAcumulada

            let parametro = {
              taxas,
              acumulados,
              tipoIndice: 'IGPM',
            }

            useJwt
              .post('cadastro/instituto/SetImportarTaxas', parametro)
              .then((response) => {
                if (response) {
                  this.$message.success('Cadastro realizada com sucesso!')
                  this.$emit('fecharModal')
                } else {
                  this.$message.error('Falha no Cadastro!')
                }
              })
              .catch((error) => {
                console.error(error)
                this.$message.error('Ocorreu um erro ao realizar a Cadastro!')
              })
          }
        })
      },
      montaAnoOpcoes() {
        let anoAtual = new Date().getFullYear()
        let anoInicial = new Date().getFullYear() - 2
        let anoOpcoes = []
        for (let i = anoAtual; i >= anoInicial; i--) {
          anoOpcoes.push({ value: i, text: i })
        }
        this.anoOpcoes = anoOpcoes
      },
    },
  }
</script>
