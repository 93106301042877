Cleave
<template>
  <b-card>
    <validation-observer ref="formRestricoes">
      <b-form>
        <b-card-text class="mb-1">
          <small class="textoObservacao"> Para mais informações do parâmetro, passe o mouse por cima dele. </small>
        </b-card-text>
        <b-row>
          <!-- Tipo de Cálculo -->
          <b-col md="3">
            <b-form-group
              label="Tipo de Cálculo"
              label-for="tipoCalculo"
              v-b-tooltip.hover.right="'Seleciona os tipos de cálculo que serão utilizados na geração de propostas!'"
            >
              <validation-provider #default="{ errors }" name="Tipo de Cálculo" rules="required">
                <b-input-group>
                  <v-select-pt
                    class="w-100"
                    multiple
                    id="tipoCalculo"
                    name="tipoCalculo"
                    v-model="tipoCalculo"
                    :options="tipoCalculoOpcoes"
                    :reduce="(options) => options.value"
                    placeholder="Selecione:"
                    label="text"
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                  ></v-select-pt>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Margem Consignável -->
          <b-col sm="2">
            <b-form-group
              label="Margem Consignável (em %)"
              label-for="margemConsignavel"
              v-b-tooltip.hover.bottom="'Parametriza a margem consignável que será utilizada na geração de propostas!'"
            >
              <validation-provider #default="{ errors }" name="Margem Consignável" rules="required|max_value:100">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    id="margemConsignavel"
                    v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^\d]/ }"
                    v-model="optionsLocal.margemConsignavel"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Margem Consignável (em %)"
                    name="Margem Consignável (em %)"
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Limitador de margem -->

          <b-col sm="2" v-if="tipoCalculo.includes('posFixado')">
            <b-form-group
              label="Limitador Margem (em %)"
              label-for="limitadorMargem"
              v-b-tooltip.hover.bottom="'Parametriza o limitador de margem que será utilizado na geração de propostas!'"
            >
              <validation-provider #default="{ errors }" name="Limitador Margem" rules="required|max_value:100">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    id="limitadorMargem"
                    v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^\d]/ }"
                    v-model="optionsLocal.limitadorMargem"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Limitador Margem"
                    name="Limitador Margem"
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                  />

                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Utiliza Utiliza Taxa de Float -->
          <b-col md="2">
            <b-form-group
              label="Utiliza Taxa de Float"
              label-for="utilizaFloatFinanceiro"
              v-b-tooltip.hover.bottom="
                'O parâmetro habilita a utilização de Float Financeiro na aprovação das propostas onde o cálculo leva em conta o tempo entre a aprovação do contrato e o repasse da primeira parcela, acrescido de um ajuste de 2 dias. Esta métrica é essencial para determinar o valor adicional do float, que será incorporado ao saldo devedor inicial e amortizado durante o período do contrato.'
              "
            >
              <b-form-checkbox
                :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                id="utilizaFloatFinanceiro"
                name="utilizaFloatFinanceiro"
                button-variant="primary"
                v-model="optionsLocal.utilizaFloatFinanceiro"
                :options="[
                  { value: true, text: 'Sim' },
                  { value: false, text: 'Não' },
                ]"
              />
            </b-form-group>
          </b-col>

          <!-- Utiliza Taxa de IOF -->
          <b-col md="2" v-if="userData.role == 'master' || userData.GrupoAcesso.ConfiguracoesSistemaRestricaoAliquotasIOF">
            <b-form-group
              label="Utiliza Taxa de IOF"
              label-for="utilizaIOF"
              v-b-tooltip.hover.bottom="'Parâmetro habilita a taxa de IOF na geração de propostas!'"
            >
              <b-form-checkbox
                :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                id="utilizaIOF"
                name="utilizaIOF"
                button-variant="primary"
                v-model="optionsLocal.utilizaIOF"
                :options="[
                  { value: true, text: 'Sim' },
                  { value: false, text: 'Não' },
                ]"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="2"
            v-if="
              optionsLocal.utilizaIOF &&
              (userData.role == 'master' || userData.GrupoAcesso.ConfiguracoesSistemaRestricaoAliquotasIOF)
            "
          >
            <b-form-group
              label="Alíquota Efetiva IOF"
              label-for="aliquotaEfetivaIOF"
              v-b-tooltip.hover.bottom="'Parametrize a alíquota efetiva do IOF que será utilizada na geração de propostas!'"
            >
              <validation-provider #default="{ errors }" name="Alíquota Efetiva IOF" rules="required">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="aliquotaEfetivaIOF"
                    v-number="number"
                    v-model="optionsLocal.aliquotaEfetivaIOF"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Alíquota Efetiva IOF"
                    name="Aliquota Efetiva"
                  />

                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="2"
            v-if="
              optionsLocal.utilizaIOF &&
              (userData.role == 'master' || userData.GrupoAcesso.ConfiguracoesSistemaRestricaoAliquotasIOF)
            "
          >
            <b-form-group
              label="Alíquota Diária IOF"
              label-for="aliquotaDiariaIOF"
              v-b-tooltip.hover.bottom="'Parametrize a alíquota Diária do IOF que será utilizada na geração de propostas!'"
            >
              <validation-provider #default="{ errors }" name="Alíquota Diária IOF" rules="required|maior_decimal:3|zero">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <Cleave
                    :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
                    id="aliquotaDiariaIOF"
                    type="text"
                    :class="['form-control', { 'is-invalid': errors.length > 0 }]"
                    v-model="optionsLocal.aliquotaDiariaIOF"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Alíquota Diária IOF"
                    name="Aliquota Diária"
                    :raw="false"
                    :options="options.cleaveAliqutoDiariaIOF"
                  >
                    <i v-if="errors.length" class="fas fa-exclamation-circle"></i>
                  </Cleave>
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          style="width: 160px"
          variant="primary"
          class="mt-2 mr-1"
          @click.prevent="authESalve"
          :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
        >
          Salvar
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          style="width: 160px"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
          @click.prevent="limpar"
          :disabled="!acesso.ConfiguracoesSistemaRestricaoEditar"
        >
          Limpar
        </b-button>
      </b-form>
    </validation-observer>
    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card>
</template>

<script>
  import vSelect from 'vue-select'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, max_value, maior_decimal } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '../../../../error/nao-autorizado-modal.vue'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { formatarParaNumber } from '@/libs/utils'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ValidationProvider,
      vSelect,
      ValidationObserver,
      required,
      maior_decimal,
      max_value,
      ToastificationContent,
      Ripple,
      AutenticaSenha,
      NaoAutorizadoModal,
      Cleave,
      formatarParaNumber,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: {
          margemConsignavel: '',
          limitadorMargem: '',
          utilizaIOF: false,
          utilizaFloatFinanceiro: false,
          tipoCalculoPrice: false,
          tipoCalculoSAC: false,
          tipoCalculoPosFixado: false,
          aliquotaEfetivaIOF: '',
          aliquotaDiariaIOF: '',
        },
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        tipoCalculo: [],
        acesso: {},
        items: [],
        number: {},
        optionsLocal: this.generalData ?? {
          margemConsignavel: '',
          limitadorMargem: '',
          utilizaIOF: false,
          utilizaFloatFinanceiro: false,
          tipoCalculoPrice: false,
          tipoCalculoSAC: false,
          tipoCalculoPosFixado: false,
          aliquotaEfetivaIOF: '',
          aliquotaDiariaIOF: '',
        },
        tipoCalculoOpcoes: [
          { value: 'price', text: 'PRICE' },
          { value: 'sac', text: 'SAC' },
          { value: 'posFixado', text: 'PÓS-FIXADO' },
        ],
        options: {
          cleaveAliqutoDiariaIOF: {
            delimiters: ['', ','],
            numericOnly: true,
            blocks: [1, 0, 4],
          },
        },
        acesso: {
          ConfiguracoesSistemaRestricaoEditar: false,
        },
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaRestricaoEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaRestricaoEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaRestricaoEditar
      }
      if (this.generalData.tipoCalculoPrice) this.tipoCalculo.push('price')
      if (this.generalData.tipoCalculoSAC) this.tipoCalculo.push('sac')
      if (this.generalData.tipoCalculoPosFixado) this.tipoCalculo.push('posFixado')
    },
    methods: {
      limpar() {
        this.tipoCalculo = []
        this.optionsLocal = {
          ...this.optionsLocal,
          margemConsignavel: null,
          limitadorMargem: null,
          utilizaFloatFinanceiro: false,
          utilizaIOF: false,
          aliquotaEfetivaIOF: null,
          aliquotaDiariaIOF: null,
        }
        this.$refs.formRestricoes.reset()
      },
      authESalve(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formRestricoes.validate().then((success) => {
          if (success) {
            this.items.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        this.optionsLocal.tipoCalculoPrice = this.tipoCalculo.includes('price') ? true : false
        this.optionsLocal.tipoCalculoSAC = this.tipoCalculo.includes('sac') ? true : false
        this.optionsLocal.tipoCalculoPosFixado = this.tipoCalculo.includes('posFixado') ? true : false

        const dados = {
          ...this.optionsLocal,
          margemConsignavel: formatarParaNumber(this.optionsLocal.margemConsignavel),
          tipoCalculoPrice: this.optionsLocal.tipoCalculoPrice,
          tipoCalculoSAC: this.optionsLocal.tipoCalculoSAC,
          tipoCalculoPosFixado: this.optionsLocal.tipoCalculoPosFixado,
          limitadorMargem: formatarParaNumber(this.optionsLocal.limitadorMargem),
          utilizaIOF: this.optionsLocal.utilizaIOF,
          utilizaFloatFinanceiro: this.optionsLocal.utilizaFloatFinanceiro,
          aliquotaEfetivaIOF: formatarParaNumber(this.optionsLocal.aliquotaEfetivaIOF) ?? null,
          aliquotaDiariaIOF: formatarParaNumber(this.optionsLocal.aliquotaDiariaIOF) ?? null,
        }
        if (!this.optionsLocal.id) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erro: O instituto a ser configurado não está sendo identificado, reinicie o sistema. ',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else {
          useJwt
            .put(`restricoes/tipoCalculo/${this.optionsLocal.id}`, dados)
            .then(() => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch((error) => {
              console.error(error)
              this.$message.error('Erro ao salvar os parâmetros de tipo de cálculo')
            })
        }
      },
    },
    watch: {
      tipoCalculo(newValue) {
        if (!newValue.includes('posFixado')) {
          this.optionsLocal.limitadorMargem = ''
        }
      },
    },
  }
</script>

<style>
  .is-required {
    border-color: red;
  }
</style>
