<template>
  <b-card title="Assinatura do Contrato">
    <h5 class="text-danger mb-10">Limite de caracteres: 160 por mensagem</h5>

    <h5>Mensagem para tomador</h5>
    <b-form-group label-for="text" class="tw-text-lg">
      <vue-editor
        id="text"
        name="text"
        ref="editor"
        rows="3"
        v-model="mensagem.tomador"
        placeholder="Digite aqui uma mensagem..."
        class="tw-max-h-[50vh] tw-overflow-y-auto"
        style="width: 100% !important"
        @text-change="onTextChange"
      ></vue-editor>
    </b-form-group>
    <div class="text-right grey--text text-sm mt-1">{{ mensagem.tomador.length }} / 160</div>

    <b-row md="12">
      <b-col align="right">
        <b-button variant="primary" style="margin-top: 21px; width: 160px" @click="salvar"> Salvar </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import { VueEditor } from 'vue2-editor'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      VueEditor,
    },

    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        mensagem: {
          tomador: '',
        },
        maxLength: 160,
      }
    },
    mounted() {
      this.carregarParams()
    },

    methods: {
      onTextChange() {
        const quill = this.$refs.editor.quill
        const text = quill.getText().trim()
        const len = text.length

        if (len > this.maxLength) {
          quill.deleteText(this.maxLength, len)
          this.mensagem.tomador = text.substring(0, this.maxLength)
        } else {
          this.mensagem.tomador = text
        }
      },

      carregarParams() {
        useJwt
          .get(`instituto/mensagem/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.mensagem = {
              tomador: response.data.mensagemTomador,
            }
          })
          .catch((error) => {
            this.$message.error('Houve um erro ao carregar parâmetros')
          })
      },

      salvar() {
        const data = {
          mensagemTomador: this.mensagem.tomador,
        }
        useJwt
          .post(`instituto/mensagem/${this.userData.institutoSelecionado}`, data)
          .then((res) => {
            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            this.$message.error('Houve um erro ao cadastrar a mensagem')
            return
          })
      },
    },
  }
</script>
