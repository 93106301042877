<template>
  <b-card>
    <validation-observer ref="formRestricoes">
      <b-form>
        <b-row>
          <!-- Índice de atualização  -->
          <b-col md="3">
            <b-form-group label="Índice Atualização" label-for="indiceAtualizacao">
              <validation-provider #default="{ errors }" name="Índice Atualização" rules="required">
                <b-form-select
                  name="Índice Atualização"
                  id="boletoIndiceAtualizacao"
                  v-model.number="optionsLocal.boletoIndiceAtualizacao"
                  :options="tipoIndiceAtualizacao"
                  :disabled="!acesso.ConfiguracoesSistemaParametroArrecadacaoEditar"
                >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Juros Após Vencimento -->
          <b-col sm="3">
            <b-form-group label="Juros Após Vencimento" label-for="limiteParcelas">
              <validation-provider #default="{ errors }" name="Juros Após Vencimento" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    id="boletoJurosAposVencimento"
                    v-model.number="optionsLocal.boletoJurosAposVencimento"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Juros (%)"
                    type="number"
                    :disabled="!acesso.ConfiguracoesSistemaParametroArrecadacaoEditar"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Tipo de Juros  -->
          <b-col md="3">
            <b-form-group label="Tipo de Juros" label-for="tipoJuros">
              <validation-provider #default="{ errors }" name="Tipo de Juros" rules="required">
                <b-form-select
                  name="Tipo de Juros"
                  id="boletoTipoJuros"
                  v-model.number="optionsLocal.boletoTipoJuros"
                  :options="tipoJuros"
                  :disabled="!acesso.ConfiguracoesSistemaParametroArrecadacaoEditar"
                >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Multa -->
          <b-col sm="3">
            <b-form-group label="Multa" label-for="multa">
              <validation-provider #default="{ errors }" name="Multa" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    id="multa"
                    v-model.number="optionsLocal.boletoMulta"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Multa (%)"
                    type="number"
                    :disabled="!acesso.ConfiguracoesSistemaParametroArrecadacaoEditar"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Multa Após  -->
          <b-col md="3">
            <b-form-group label="Multa Após" label-for="tipoMulta">
              <validation-provider #default="{ errors }" name="Multa Após" rules="required">
                <b-form-select
                  name="Multa Após"
                  v-model.number="optionsLocal.boletoMultaApos"
                  :options="tipoMultaApos"
                  :disabled="!acesso.ConfiguracoesSistemaParametroArrecadacaoEditar"
                >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Banco Emissor  -->
          <b-col md="3">
            <b-form-group label="Banco Emissor" label-for="boletoBancoEmissor">
              <validation-provider #default="{ errors }" name="Banco Emissor" rules="required">
                <v-select-pt
                  id="boletoBancoEmissor"
                  name="boletoBancoEmissor"
                  v-model="optionsLocal.boletoBancoEmissor"
                  :options="opcoesBancos"
                  :reduce="(option) => option.id"
                  label="nome"
                  :disabled="!acesso.ConfiguracoesSistemaParametroArrecadacaoEditar"
                ></v-select-pt>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Dia Útil  -->
          <b-col md="3">
            <b-form-group label="Dia Útil" label-for="boletoDiaUtil">
              <validation-provider #default="{ errors }" name="Dia Útil" rules="required">
                <b-form-select
                  id="boletoDiaUtil"
                  name="boletoDiaUtil"
                  v-model.number="optionsLocal.boletoDiaUtil"
                  :options="opcoesBoletoDiaUtil"
                  :disabled="!acesso.ConfiguracoesSistemaParametroArrecadacaoEditar"
                >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          style="width: 160px"
          variant="primary"
          class="mt-2 mr-1"
          @click.prevent="authESalve"
          :disabled="!acesso.ConfiguracoesSistemaParametroArrecadacaoEditar"
        >
          Salvar
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          style="width: 160px"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
          @click.prevent="limpar"
          :disabled="!acesso.ConfiguracoesSistemaParametroArrecadacaoEditar"
        >
          Limpar
        </b-button>
      </b-form>
    </validation-observer>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card>
</template>

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import VSelectPT from '@/@core/components/v-select-pt/VSelectPT.vue'

  export default {
    components: {
      BCardActions,
      AutenticaSenha,
      ValidationObserver,
      ValidationProvider,
      ToastificationContent,
      required,
      VSelectPT,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        tipoMultaApos: [
          { value: '', text: 'Selecione' },
          { value: '1', text: '1 Meses' },
          { value: '2', text: '2 Meses' },
          { value: '3', text: '3 Meses' },
          { value: '4', text: '4 Meses' },
          { value: '5', text: '5 Meses' },
          { value: '6', text: '6 Meses' },
          { value: '7', text: '7 Meses' },
          { value: '8', text: '8 Meses' },
          { value: '9', text: '9 Meses' },
          { value: '10', text: '10 Meses' },
          { value: '11', text: '11 Meses' },
          { value: '12', text: '12 Meses' },
        ],
        tipoIndiceAtualizacao: [
          { value: '', text: 'Selecione' },
          { value: 'IPCA', text: 'IPCA' },
          { value: 'INPC', text: 'INPC' },
        ],
        tipoJuros: [
          { value: '', text: 'Selecione' },
          { value: 'aoMesCalculoDiario', text: 'Ao Mês - Cálculo diário' },
          { value: 'aoMesNaoCumulativo', text: 'Ao Mês - Não Cumulativo' },
        ],
        opcoesBoletoDiaUtil: [
          { value: '', text: 'Selecione' },
          { value: 'anteriorADataDeVencimento', text: 'Anterior à data de vencimento' },
          { value: 'posteriorADataDeVencimento', text: 'Posterior à data de vencimento' },
        ],
        items: [],
        opcoesBancos: [],
        acesso: {
          ConfiguracoesSistemaParametroArrecadacaoEditar: false,
        },
      }
    },
    computed: {},
    mounted() {
      this.carregarBancos()
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaParametroArrecadacaoEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaParametroArrecadacaoEditar =
          this.userData.GrupoAcesso.ConfiguracoesSistemaParametroArrecadacaoEditar
      }
    },
    methods: {
      limpar() {
        this.optionsLocal = {
          boletoMultaApos: '',
          boletoMulta: '',
          boletoTipoJuros: '',
          boletoIndiceAtualizacao: '',
          boletoJurosAposVencimento: '',
        }
      },
      authESalve(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formRestricoes.validate().then((success) => {
          if (success) {
            this.items.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        if (!this.optionsLocal.id) {
          return
        }

        useJwt
          .put(`cadastro/instituto/parametroBoleto/${this.optionsLocal.id}`, this.optionsLocal)
          .then(() => {
            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao salvar os parâmetros de boleto')
          })
      },
      carregarBancos() {
        useJwt
          .get('utils/bancos')
          .then((response) => {
            this.opcoesBancos = response.data
            this.opcoesBancos.forEach((banco) => {
              if (banco.codigo) banco.nome = String(banco.codigo) + ' - ' + String(banco.nome)
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style lang="scss"></style>
