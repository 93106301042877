

















































































































































































































































import useJwt from '@/auth/jwt/useJwt'
import { formatarParaNumber } from '@/libs/utils'
import { message, sweetAlert } from '@/main'
import AutenticaSenha from '@/views/pages/auth/autenticaSenha.vue'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { evaluate } from 'mathjs'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default defineComponent({
  props: {
    generalData: {
      type: Object || null,
      default: () => {},
    },
  },
  setup(props) {
    const modalAuthSenha = ref(undefined)
    const formCompTaxa = ref(undefined)

    const optionsLocal = ref({
      taxaindiceInflacionarioPosFixado: 0.0,
      taxajurosAtuariaisPosFixado: 0.0,
      taxafundoGarantidorPosFixado: 0.0,
      taxaAdministrativaPosFixado: 0.0,
      taxaOportunidadePosFixado: 0.0,
      taxaOscilacaoRiscosPosFixado: 0.0,
      taxaSpreadPosFixado: 0.0,
      mode: '',
      id: 0,
    })
    const userData = ref(this.$jwtDecode(localStorage.getItem('userData'))['userData'])
    const number = ref(0.0)
    const tipoIndice = ref('')
    const acesso = ref({
      ConfiguracoesSistemaCompTaxasConsultar: false,
      ConfiguracoesSistemaCompTaxasEditar: false,
    })
    const salvarOk = ref(false)
    const permiteDeflacao = ref(false)
    const valorAlterado = ref(false)
    const pesquisar = ref({
      tipoOpcoes: 1,
      descricao: '',
      perPage: 25,
      currentPage: 1,
    })
    const optionsSimNao = ref([
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ])

    optionsLocal.value.taxaAdministrativaPosFixado = props.generalData?.taxaAdministrativaPosFixado
    optionsLocal.value.taxaOscilacaoRiscosPosFixado = props.generalData?.taxaOscilacaoRiscosPosFixado
    optionsLocal.value.taxafundoGarantidorPosFixado = props.generalData?.taxafundoGarantidorPosFixado
    optionsLocal.value.taxajurosAtuariaisPosFixado = props.generalData?.taxajurosAtuariaisPosFixado
    optionsLocal.value.taxaOportunidadePosFixado = props.generalData?.taxaOportunidadePosFixado
    optionsLocal.value.taxaSpreadPosFixado = props.generalData?.taxaSpreadPosFixado
    permiteDeflacao.value = props.generalData?.permiteDeflacao

    const mounted = onMounted(() => {
      if (userData.value.role == 'master') {
        acesso.value.ConfiguracoesSistemaCompTaxasConsultar = true
        acesso.value.ConfiguracoesSistemaCompTaxasEditar = true
      } else {
        acesso.value.ConfiguracoesSistemaCompTaxasConsultar =
          userData.value.GrupoAcesso.ConfiguracoesSistemaCompTaxasConsultar
        acesso.value.ConfiguracoesSistemaCompTaxasEditar = userData.value.GrupoAcesso.ConfiguracoesSistemaCompTaxasEditar
      }

      carregaIndice()
    })

    const carregaIndice = () => {
      const tipoIndiceSelecionado = userData.value.tipoIndiceSelecionado ? userData.value.tipoIndiceSelecionado : 'IPCA'

      useJwt
        .post('indices/getPesquisarIndices', { tipoIndice: tipoIndiceSelecionado })
        .then((response) => {
          optionsLocal.value.taxaindiceInflacionarioPosFixado = response.data.taxa
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const atualizarCamposDeCalculo = () => {
      if (
        !(
          optionsLocal.value.taxaOportunidadePosFixado &&
          optionsLocal.value.taxaAdministrativaPosFixado &&
          optionsLocal.value.taxaOscilacaoRiscosPosFixado &&
          optionsLocal.value.taxafundoGarantidorPosFixado &&
          optionsLocal.value.taxajurosAtuariaisPosFixado
        )
      ) {
        message.error('Todos os campos de devem ser preenchidos!')
        salvarOk.value = false
        return
      }

      calcularTaxaDeOportunidade()
    }

    const valoresAlterados = () => {
      valorAlterado.value = true
    }

    const calcularTaxaDeOportunidade = () => {
      const taxaDeOportunidade = Number((formatarParaNumber(optionsLocal.value.taxaOportunidadePosFixado) / 100).toFixed(4))
      const jurosAtuarial = Number((formatarParaNumber(optionsLocal.value.taxajurosAtuariaisPosFixado) / 100).toFixed(4))
      const taxaAdministrativa = Number((formatarParaNumber(optionsLocal.value.taxaAdministrativaPosFixado) / 100).toFixed(4))
      const taxaOscilacaoRiscos = Number(
        (formatarParaNumber(optionsLocal.value.taxaOscilacaoRiscosPosFixado) / 100).toFixed(4),
      )
      const taxaFundoGarantia = Number((formatarParaNumber(optionsLocal.value.taxafundoGarantidorPosFixado) / 100).toFixed(4))

      const calculoSpread = Number(
        evaluate('(( 1 + A ) * ( 1 + B ) * ( 1 + C ) * (1 + D) * (1 + E)) - 1', {
          A: taxaDeOportunidade,
          B: jurosAtuarial,
          C: taxaAdministrativa,
          D: taxaOscilacaoRiscos,
          E: taxaFundoGarantia,
        }).toFixed(4),
      )

      salvarOk.value = true
      optionsLocal.value.taxaSpreadPosFixado = calculoSpread
    }

    const authESalve = () => {
      formCompTaxa.value.validate().then((success) => {
        if (success) {
          optionsLocal.value.mode = 'authESalve'
          modalAuthSenha.value.show()
        }
      })
    }

    const autenticarMethod = (data) => {
      if (data) {
        modalAuthSenha.value.hide()
        if (optionsLocal.value.mode == 'authESalve') {
          salvar()
        }
      }
    }

    const salvar = () => {
      const dados = {
        taxaAdministrativaPosFixado: formatarParaNumber(optionsLocal.value.taxaAdministrativaPosFixado),
        taxaOscilacaoRiscosPosFixado: formatarParaNumber(optionsLocal.value.taxaOscilacaoRiscosPosFixado),
        taxafundoGarantidorPosFixado: formatarParaNumber(optionsLocal.value.taxafundoGarantidorPosFixado),
        taxajurosAtuariaisPosFixado: formatarParaNumber(optionsLocal.value.taxajurosAtuariaisPosFixado),
        taxaOportunidadePosFixado: formatarParaNumber(optionsLocal.value.taxaOportunidadePosFixado),
        taxaSpreadPosFixado: formatarParaNumber(optionsLocal.value.taxaSpreadPosFixado),
        permiteDeflacao: permiteDeflacao.value,
        taxaIndiceInflacionario: formatarParaNumber(optionsLocal.value.taxaindiceInflacionarioPosFixado),
      }

      useJwt
        .put(`indices/taxasPosFixado/${userData.value.institutoSelecionado}`, dados)
        .then(() => {
          salvarOk.value = false

          sweetAlert({
            title: 'Atenção!',
            text: 'Registro alterado com sucesso',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch((error) => {
          salvarOk.value = true
          console.error(error)
        })
    }

    const atualizaIndice = (tipoIndice) => {
      tipoIndice.value = tipoIndice
    }

    return {
      userData,
      number,
      tipoIndice,
      modalAuthSenha,
      acesso,
      permiteDeflacao,
      salvarOk,
      valorAlterado,
      pesquisar,
      carregaIndice,
      formCompTaxa,
      atualizarCamposDeCalculo,
      calcularTaxaDeOportunidade,
      valoresAlterados,
      authESalve,
      autenticarMethod,
      salvar,
      atualizaIndice,
      optionsLocal,
      optionsSimNao,
      AutenticaSenha,
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    AutenticaSenha,
  },
  directives: {
    Ripple,
  },
})
