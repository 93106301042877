<template>
  <div>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="PenToolIcon" size="18" class="mr-50" />
          <span class="tw-text-normal">Assinatura do Contrato</span>
        </template>
        <ConfiguracoesSistemaMensagemContrato />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
  import ConfiguracoesSistemaMensagemContrato from './ConfiguracoesSistemaMensagemContrato.vue'

  export default {
    components: {
      ConfiguracoesSistemaMensagemContrato,
    },
  }
</script>
<style></style>
