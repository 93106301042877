<template>
  <b-card>
    <validation-observer ref="formCompTaxa">
      <b-form>
        <b-card-text class="mb-1">
          <small class="textoObservacao"> Todos os valores neste cadastro devem ser informados com 2 casas decimais. </small>
        </b-card-text>
        <b-row>
          <!-- Taxa de Juros Mensal -->
          <b-col sm="3">
            <b-form-group label="Taxa de Empréstimos [Ao Mês]" label-for="taxaEmprestimoMensal">
              <validation-provider #default="{ errors }" name="Taxa de Empréstimos [Ao Mês]" rules="required">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    type="text"
                    v-number="number"
                    id="taxaEmprestimoMensal"
                    v-model="optionsLocal.taxaEmprestimoMensal"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Taxa de Empréstimos [Ao Mês]"
                    @input="valoresAlterados"
                    placeholder="Taxa de Empréstimos [Ao Mês]"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Taxa de Juros Mensal -->
          <b-col sm="3">
            <b-form-group label="Performance" label-for="taxaDeOportunidade">
              <validation-provider #default="{ errors }" name="Superávit Atuarial" rules="required">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    type="text"
                    v-number="number"
                    id="taxaDeOportunidade"
                    v-model="optionsLocal.taxaDeOportunidade"
                    :disabled="true"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Superávit Atuarial"
                    placeholder="Superávit Atuarial"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Juros Atuarial -->
          <b-col sm="3">
            <b-form-group label="Juros Atuariais [Ao ano]" label-for="jurosAtuarial">
              <validation-provider #default="{ errors }" name="Juros Atuariais" vid="Juros Atuariais" rules="required">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    type="text"
                    v-number="number"
                    id="jurosAtuarial"
                    v-model="optionsLocal.jurosAtuarial"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Juros Atuariais"
                    @input="valoresAlterados"
                    placeholder="Juros Atuariais"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Taxa de Administração -->
          <b-col sm="3">
            <b-form-group label="Taxa de Administração [Ao ano]" label-for="taxaAdministrativa">
              <validation-provider
                #default="{ errors }"
                name="Taxa de Administração"
                vid="Taxa de Administração"
                rules="required"
              >
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    type="text"
                    v-number="number"
                    id="taxaAdministrativa"
                    v-model="optionsLocal.taxaAdministrativa"
                    class="form-control-merge"
                    name="Taxa de Administração"
                    @input="valoresAlterados"
                    placeholder="Taxa de Administração"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Oscilação de Riscos -->
          <b-col sm="3">
            <b-form-group label="Oscilação de Riscos [Ao Ano]" label-for="Oscilação de Riscos">
              <validation-provider #default="{ errors }" name="Oscilação de Riscos" vid="Oscilação de Riscos" rules="required">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    type="text"
                    v-number="number"
                    id="taxaOscilacaoRiscos"
                    v-model="optionsLocal.taxaOscilacaoRiscos"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Oscilação de Riscos"
                    placeholder="Oscilação de Riscos"
                    @input="valoresAlterados"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Fundo Garantidor/Seguro Prestamista -->
          <b-col sm="3">
            <b-form-group label="Fundo Garantidor/Seguro Prestamista [Ao Ano]" label-for="Fundo Garantidor/Seguro Prestamista">
              <validation-provider
                #default="{ errors }"
                name="Fundo Garantidor/Seguro Prestamista"
                vid="Fundo Garantidor/Seguro Prestamista"
                rules="required"
              >
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    type="text"
                    v-number="number"
                    id="taxaFundoGarantia"
                    v-model="optionsLocal.taxaFundoGarantia"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Fundo Garantidor/Seguro Prestamista"
                    @input="valoresAlterados"
                    placeholder="Fundo Garantidor/Seguro Prestamista"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Índice Inflacionário -->
          <b-col sm="3">
            <b-form-group
              :label="tipoIndice ? 'Índice Inflacionário [Ao ano] - ' + tipoIndice : 'Índice Inflacionário [Ao ano]'"
              label-for="IndiceInflacionario"
            >
              <validation-provider #default="{ errors }" name="Índice Inflacionário" vid="IndiceInflacionario" rules="required">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    type="text"
                    v-number="number"
                    class="form-control-merge"
                    name="IndiceInflacionario"
                    id="taxaIndiceInflacionario"
                    placeholder="Índice Inflacionário"
                    :state="errors.length > 0 ? false : null"
                    v-model="optionsLocal.taxaIndiceInflacionario"
                    :readonly="true"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Taxa de Juros Anual -->
          <b-col sm="3">
            <b-form-group label="Taxa de Empréstimos [Ao ano]" label-for="Taxa de Empréstimos">
              <validation-provider
                #default="{ errors }"
                name="Taxa de Empréstimos [Ao ano]"
                vid="Taxa de Empréstimos [Ao ano]"
                rules="required"
              >
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    type="text"
                    v-number="number"
                    id="taxaEmprestimoAnual"
                    v-model="optionsLocal.taxaEmprestimoAnual"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Taxa de Empréstimos [Ao ano]"
                    :readonly="true"
                    placeholder="Taxa de Empréstimos [Ao ano]"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" align="left" v-show="!acesso.ConfiguracoesSistemaCompTaxasEditar">
            <b-card-text class="mb-1 text-danger">
              A edição desta página está desativada. Seu usuário não tem essa permissão
            </b-card-text>
          </b-col>

          <b-col cols="12" v-show="acesso.ConfiguracoesSistemaCompTaxasEditar">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              style="width: 160px"
              @click.prevent="atualizarCamposDeCalculo"
            >
              Recalcular
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              style="width: 160px"
              @click.prevent="authESalve"
              :disabled="!salvarOk && !valorAlterado"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <div>
      <hr />
      <h2 class="mt-2">Histórico Composição de Taxa</h2>
      <ComposicaoTaxa />
    </div>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card>
</template>

<script>
  //validacao
  import useJwt from '@/auth/jwt/useJwt'
  import { required } from '@validations'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  //formatar numeros para porcentagem
  import { formatarParaNumber, formatarValor } from '@/libs/utils'
  //alertas
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '../../../error/nao-autorizado-modal.vue'
  // Biblioteca de cálculo
  import * as mathjs from 'mathjs'
  import ComposicaoTaxa from './composicao-taxa/ComposicaoTaxa.vue'

  export default {
    components: {
      ComposicaoTaxa,
      ValidationObserver,
      ValidationProvider,
      required,
      AutenticaSenha,
      NaoAutorizadoModal,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        number: 0.0,
        tipoIndice: '',
        acesso: {
          ConfiguracoesSistemaCompTaxasEditar: false,
        },
        salvarOk: false,
        valorAlterado: false,
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 25, currentPage: 1 },
      }
    },
    created() {},
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaCompTaxasEditar = true
      } else {
        this.acesso.ConfiguracoesSistemaCompTaxasEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaCompTaxasEditar
      }

      this.tipoIndice = this.optionsLocal.tipoIndiceSelecionado
      // this.$store.commit("tipoIndiceSelecionado", this.userData.Instituto.tipoIndiceSelecionado);
      this.carregaIndice()
    },
    methods: {
      valoresAlterados() {
        this.salvarOk = false
        this.valorAlterado = false
      },
      atualizarCamposDeCalculo() {
        if (
          !(
            this.optionsLocal.taxaIndiceInflacionario &&
            this.optionsLocal.taxaAdministrativa &&
            this.optionsLocal.taxaOscilacaoRiscos &&
            this.optionsLocal.taxaFundoGarantia &&
            this.optionsLocal.taxaEmprestimoMensal
          )
        ) {
          this.$message.error('Todos os campos de devem ser preenchidos!')
          this.salvarOk = false
          return
        }
        this.calcularTaxaDeOportunidade()
      },
      calcularTaxaDeOportunidade() {
        if (
          !(
            this.optionsLocal.taxaIndiceInflacionario &&
            this.optionsLocal.taxaAdministrativa &&
            this.optionsLocal.taxaOscilacaoRiscos &&
            this.optionsLocal.taxaFundoGarantia &&
            this.optionsLocal.taxaEmprestimoMensal
          )
        ) {
          this.$message.error('Para calcular a Performance deve-se informar todas a Taxa de Empréstimo!')
          this.salvarOk = false
          return
        }

        this.calculaJurosAnual()

        let taxaIndiceInflacionario = mathjs.bignumber(formatarParaNumber(this.optionsLocal.taxaIndiceInflacionario) || 0)
        let jurosAtuarial = mathjs.bignumber(formatarParaNumber(this.optionsLocal.jurosAtuarial) || 0)
        let taxaAdministrativa = mathjs.bignumber(formatarParaNumber(this.optionsLocal.taxaAdministrativa) || 0)
        let taxaEmprestimoAnual = mathjs.bignumber(formatarParaNumber(this.optionsLocal.taxaEmprestimoAnual) || 0)
        let taxaOscilacaoRiscos = mathjs.bignumber(formatarParaNumber(this.optionsLocal.taxaOscilacaoRiscos) || 0)
        let taxaFundoGarantia = mathjs.bignumber(formatarParaNumber(this.optionsLocal.taxaFundoGarantia) || 0)

        taxaEmprestimoAnual = formatarParaNumber(taxaEmprestimoAnual)
        taxaIndiceInflacionario = formatarParaNumber(taxaIndiceInflacionario)
        jurosAtuarial = formatarParaNumber(jurosAtuarial)
        taxaAdministrativa = formatarParaNumber(taxaAdministrativa)
        taxaOscilacaoRiscos = formatarParaNumber(taxaOscilacaoRiscos)
        taxaFundoGarantia = formatarParaNumber(taxaFundoGarantia)

        let calculoDaTaxaDeOportunidade = Number(
          mathjs
            .evaluate(`( jurosAoAno - ( A + B + C + D + E) )`, {
              jurosAoAno: taxaEmprestimoAnual,

              A: taxaAdministrativa,
              B: taxaOscilacaoRiscos,
              C: taxaFundoGarantia,
              D: jurosAtuarial,
              E: taxaIndiceInflacionario,
            })
            .toFixed(4),
        )
        calculoDaTaxaDeOportunidade = calculoDaTaxaDeOportunidade * 100 <= 0 ? 0 : calculoDaTaxaDeOportunidade

        this.optionsLocal.taxaDeOportunidade = formatarValor(calculoDaTaxaDeOportunidade)
        this.salvarOk = true
      },
      calculaJurosAnual() {
        let taxaEmprestimoMensal = formatarParaNumber(this.optionsLocal.taxaEmprestimoMensal) / 100

        let formula = '((A + 1) ^ 12 - 1) * 100'
        let escopo = {
          A: taxaEmprestimoMensal,
        }

        this.optionsLocal.taxaEmprestimoAnual = Number(mathjs.evaluate(formula, escopo)).toFixed(2)
      },
      carregaIndice() {
        let tipoIndiceSelecionado = this.userData.Instituto.tipoIndiceSelecionado
          ? this.userData.Instituto.tipoIndiceSelecionado
          : 'IPCA'

        useJwt
          .post('indices/getPesquisarIndices', { tipoIndice: tipoIndiceSelecionado })
          .then((response) => {
            let taxaSemFormatar = formatarValor(response.data.taxaAcumulado)
            taxaSemFormatar = taxaSemFormatar.replace('R$ ', '')
            this.optionsLocal.taxaIndiceInflacionario = taxaSemFormatar
            this.optionsLocal.taxaIndiceInflacionarioMensal = response.data.taxa
            this.calculaJurosAnual()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      authESalve() {
        this.$refs.formCompTaxa.validate().then((success) => {
          if (success) {
            this.optionsLocal.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.optionsLocal.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        const dados = {
          ...this.optionsLocal,
          taxaAdministrativa: formatarParaNumber(this.optionsLocal.taxaAdministrativa),
          taxaOscilacaoRiscos: formatarParaNumber(this.optionsLocal.taxaOscilacaoRiscos),
          taxaEmprestimoAnual: formatarParaNumber(this.optionsLocal.taxaEmprestimoAnual),
          taxaFundoGarantia: formatarParaNumber(this.optionsLocal.taxaFundoGarantia),
          taxaIndiceInflacionario: formatarParaNumber(this.optionsLocal.taxaIndiceInflacionario),
          taxaEmprestimoMensal: formatarParaNumber(this.optionsLocal.taxaEmprestimoMensal),
          jurosAtuarial: formatarParaNumber(this.optionsLocal.jurosAtuarial),
          taxaDeOportunidade: formatarParaNumber(this.optionsLocal.taxaDeOportunidade),
          institutoId: this.userData.institutoSelecionado,
        }

        useJwt
          .put(`indices/putTaxas/${this.optionsLocal.id}`, dados)
          .then(() => {
            this.salvarOk = false
            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            this.salvarOk = true
            console.error(error)
          })
      },
      atualizaIndice(tipoIndice) {
        this.tipoIndice = tipoIndice
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
